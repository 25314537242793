import { useContext, useEffect, useState } from 'react'
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'
import clsx from 'clsx'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { GlobalProvider, GlobalContext } from 'context/GlobalContext'
import { OneSignalProvider, OneSignalContext } from 'context/OneSignalContext'
import * as api from 'util/api'

import 'styles/globals.css'
import { Cog8ToothIcon, HomeIcon, MapIcon, NewspaperIcon, ReceiptRefundIcon } from '@heroicons/react/24/outline'
import { Spinner } from 'components/gsys-ui'
import { Toaster } from 'react-hot-toast'

//const queryClient = new QueryClient()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 20000,
      refetchOnWindowFocus: false
    }
  }
})

const nav = [
  {
    title: 'Enquiries',
    icon: HomeIcon,
    uri: '/'
  },
  {
    title: 'Settings',
    icon: Cog8ToothIcon,
    uri: '/settings'
  }
]

const AppContainer = () => {
  return (
    <OneSignalProvider>
      <GlobalProvider>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </QueryClientProvider>
      </GlobalProvider>
    </OneSignalProvider>
  )
}

const App = () => {
  const { userId } = useContext(OneSignalContext)
  const { apir, setApir, cinfo, setCinfo, uinfo, setUinfo, brandingBlob, setBrandingBlob } = useContext(GlobalContext)
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!userId) return
    ;(async () => {
      const cinfo = await api.getCompanyInfo()
      const uinfo = await api.getUserInfo()

      if (!cinfo || !uinfo) {
        setLoading(false)
        return
      } else {
        setCinfo(cinfo)
        setUinfo(uinfo)
      }

      const getapir = await api.getApirAddr(cinfo.CompanyID)

      if (!getapir || getapir.length === 0) {
        window.location = '/error'
        return
      }

      console.log(getapir)

      if (getapir[0].CompanyId === '85be2070-f82f-42e4-9f0e-83e60427c508') {
        setApir('melk.connectplus.parts')
      } else {
        setApir(getapir[0].CompanyDomain)
      }
    })()
  }, [userId])

  useEffect(() => {
    ;(async () => {
      if (!cinfo) return
      const ciBlobId = cinfo.CompanyBrandingBlobStorageID
      const ls = false //localStorage.getItem(ciBlobId)

      if (ls) {
        setBrandingBlob(JSON.parse(ls))
      } else {
        const ciImage = await api.getImageFromBlobId(ciBlobId)
        setBrandingBlob(ciImage)
        localStorage.setItem(ciBlobId, JSON.stringify(ciImage))
      }
    })()
  }, [cinfo])

  useEffect(() => {
    if (!cinfo || !uinfo || !apir || apir === 'none') return

    api.registerDevice(
      {
        UserId: cinfo.UserID,
        OneSignalId: userId,
        CompanyId: uinfo.CompanyId,
        Acct: uinfo.CustomerKeyCode
      },
      apir
    )
  }, [cinfo, uinfo, apir])

  useEffect(() => {
    if (!uinfo || !brandingBlob) return
    if (location.pathname === '/login') navigate('/')
    setLoading(false)
  }, [uinfo, brandingBlob])

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="absolute text-white" style={{ fontFamily: 'LicensePlate', opacity: 0 }}>
          loading font
        </div>
        <Spinner size={60} color="rgb(35, 98, 172)" />
      </div>
    )

  if (location.pathname === '/login') return <Outlet />

  return (
    <>
      <div
        className="fixed z-40 w-full text-white h-[68px] shadow-md"
        style={{ backgroundColor: cinfo.ConnectPlusColor }}
      >
        <div className="flex justify-between items-center px-1 h-full">
          <div className="box-border flex flex-none justify-center p-3 w-64 h-full">
            <div
              className="w-full h-full"
              style={{
                backgroundImage: `url("${brandingBlob}")`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left center'
              }}
            />
          </div>
          <div className="p-3 text-xl font-medium text-white uppercase">
            {cinfo ? cinfo.CompanyName.replace(/"/g, '') + ' (' + uinfo.CustomerKeyCode + ')' : 'NONE'}
          </div>
        </div>
      </div>

      <div className="fixed top-0 bottom-0 left-0 z-30 w-60 bg-white border-r border-gray-300 shadow-md pt-[80px]">
        {apir !== 'none'
          ? nav.map((el, ind) => (
              <Link key={ind} to={el.uri}>
                <div
                  className={clsx(
                    'flex items-center px-4 py-3 bg-white transition-colors cursor-pointer hover:bg-gray-100',
                    {
                      'bg-gray-100': false
                    }
                  )}
                >
                  <el.icon className="mr-4 w-7 h-7" />
                  {el.title}
                </div>
              </Link>
            ))
          : nav.map(
              (el, ind) =>
                !['Tracking', 'Returns', 'News'].includes(el.title) && (
                  <Link key={ind} to={el.uri}>
                    <div
                      className={clsx(
                        'flex items-center px-4 py-3 bg-white transition-colors cursor-pointer hover:bg-gray-100',
                        {
                          'bg-gray-100': false
                        }
                      )}
                    >
                      <el.icon className="mr-4 w-7 h-7" />
                      {el.title}
                    </div>
                  </Link>
                )
            )}
      </div>

      <div className="flex pt-16 pl-60 h-full">
        <div className="pt-2 w-full h-full">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default AppContainer
