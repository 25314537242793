import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Card from 'components/ui/Card'
import ModalContainer from '../ui/ModalContainer'
import { useCheckReg } from 'util/queries'
import { Footer } from 'components/ui/form/Form'
import Button from 'components/ui/Button'

const CheckRegModal = NiceModal.create(({ reg, vehicleData, submit, cancel }) => {
  const modal = useModal()

  const handleSubmit = async (res) => {
    modal.remove()
    submit()
  }

  const handleCancel = async (res) => {
    modal.remove()
    cancel()
  }

  return (
    <ModalContainer closeFunc={cancel}>
      <Card className="!w-[500px]" pad={false}>
        <Card.Title>Vehicle confirmation</Card.Title>
        <Card.Body>
          Is this the correct vehicle:
          <div style={{ fontWeight: 600, marginBottom: 16 }}>
            {vehicleData.data.Colour} {vehicleData.data.Make} {vehicleData.data.Model}
          </div>
          <Footer>
            <Button variant="white" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="green">
              This is correct
            </Button>
          </Footer>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default CheckRegModal
