import { Field, ErrorMessage } from 'formik'

const RegTextInput = ({ onBlur, name, label, required, placeholder, size = 1, disabled }) => (
  <div>
    <Field
      name={name}
      onBlur={onBlur}
      aria-label={`Enter your ${label}`}
      aria-required={required}
      placeholder={placeholder}
      type="text"
      disabled={disabled}
      className="flex justify-center items-center placeholder-black placeholder-opacity-20 text-center rounded-md border border-black flex-0"
      style={{
        backgroundColor: 'rgb(248, 208, 56)',
        width: `${160 * size}px`,
        height: `${40 * size}px`,
        fontSize: `${22 * size}px`,
        fontFamily: 'LicensePlate'
      }}
    />

    <div className="text-sm text-red-600">
      <ErrorMessage name={name} />
    </div>
  </div>
)

export default RegTextInput
