import NiceModal from '@ebay/nice-modal-react'

import LightboxModal from 'components/MessagesColumn/LightboxModal'
import NewEnquiryModal from 'components/EnquiryColumn/NewEnquiryModal'
import DateFilterModal from 'components/EnquiryColumn/DateFilterModal'
import CheckRegModal from 'components/EnquiryColumn/CheckRegModal'
import PartListModal from 'components/EnquiryColumn/PartListModal'

NiceModal.register('lightboxModal', LightboxModal)
NiceModal.register('newEnquiryModal', NewEnquiryModal)
NiceModal.register('dateFilterModal', DateFilterModal)
NiceModal.register('checkRegModal', CheckRegModal)
NiceModal.register('partListModal', PartListModal)
