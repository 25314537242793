import { useMutation, useQueryClient } from '@tanstack/react-query'

import * as api from './api'
import { useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'

export const useCreateEnquiry = () => {
  const queryClient = useQueryClient()
  const { vehicleString, notesExtra } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (values) =>
      api.createEnquiry({
        ...values,
        Notes: `${notesExtra}${values.Notes} ${vehicleString}`
      }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['enqs'] })
    }
  })
}

export const useSendMessage = (enqId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (msg) => api.sendMessage(enqId, msg),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['enqs'] }),
        queryClient.invalidateQueries({ queryKey: ['msgs', enqId] })
      ])
    }
  })
}

export const usePlaceOrder = (enqId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (items) => api.placeOrder(items),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['enqs'] }),
        queryClient.invalidateQueries({ queryKey: ['enqRes', enqId] })
      ])
    }
  })
}
