import { useContext } from 'react'
import moment from 'moment'

import Card from 'components/ui/Card'
import { GlobalContext } from 'context/GlobalContext'
import Spinner from '../ui/Spinner'
import { useEnquiryResponse } from 'util/queries'

const EnqOrdered = ({ enqId }) => {
  const { currencySymbol } = useContext(GlobalContext)
  const { isLoading, isError, data: enqResponse, error } = useEnquiryResponse(enqId)

  if (isLoading) return <div className="flex justify-center py-4"><Spinner /></div>
  if (isError) return <div className="col-span-4">{JSON.stringify(error)}</div>

  const totalPrice = enqResponse.reduce((acc, cur) => {
    const price = parseFloat(cur.UnitPrice)
    return acc += (price * cur.Qty)
  }, 0)

  return (
    <>
      <Card className="text-center">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold uppercase">Thanks for your order!</div>
          <div>
            <div className="text-sm">Total ordered</div>
            <span className="text-4xl font-bold transition-colors">
              {currencySymbol}{totalPrice.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="flex-1 mt-4">Your parts will be with you shortly. Keep an eye on your notifications for more details.</div>
      </Card>
      {
        enqResponse.map((part, ind) => <OrderedPartCard key={ind} part={part} ind={ind + 1} />)
      }
    </>
  )
}

const OrderedPartCard = ({ part, ind }) => {
  const { currencySymbol, cinfo } = useContext(GlobalContext)
  const price = parseFloat(part.UnitPrice).toFixed(2)
  const total = (parseFloat(part.UnitPrice) * part.Qty).toFixed(2)
  const dueDate = moment(part.DeliveryDate).isSame(new Date(), 'day') ? 'Today' : moment(part.DeliveryDate).format('DD/MM/YY')

  return (
    <Card pad={false}>
      <div className="p-4">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center w-8 h-8 text-2xl font-bold text-white bg-orange-400 rounded-full">{ind}</div>
          <div className="text-2xl font-bold uppercase">{part.PartNumber}</div>
        </div>
        <div className="w-[95%] h-[2px] bg-gray-300 mx-auto my-2" />
        <div className="flex justify-around">
          <div className="text-2xl">Qty {part.Qty} @ {currencySymbol}{price}</div>
          <div className="text-2xl font-bold">{currencySymbol}{total}</div>
        </div>
        <div className="w-[95%] h-[2px] bg-gray-300 mx-auto my-2" />
        <div className="text-center">{part.ResponseDetailNotes}</div>
      </div>
      <div className="p-2 font-bold text-center text-white" style={{ backgroundColor: cinfo.ConnectPlusColor }}>
        Due: {dueDate}
      </div>
    </Card>
  )
}

export default EnqOrdered