import { useContext, useState } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import { successToast, warningToast } from 'util/helpers'
import { GlobalContext } from 'context/GlobalContext'
import { Button, Card } from 'components/gsys-ui'
import Checkbox from 'components/ui/Checkbox'
import Spinner from 'components/ui/Spinner'
import { useEnquiryResponse } from 'util/queries'
import { usePlaceOrder } from 'util/mutations'

const EnqQuoted = ({ enqId }) => {
  const { cinfo, currencySymbol } = useContext(GlobalContext)
  const [selected, setSelected] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)

  const { isLoading, isError, data: enqResponse, error } = useEnquiryResponse(enqId)
  const mutation = usePlaceOrder(enqId)

  if (isLoading) return <div className="flex justify-center py-4"><Spinner /></div>
  if (isError) return <div className="col-span-4">{JSON.stringify(error)}</div>

  const selectItem = (id) => {
    setSelected([...selected, id])
  }

  const unselectItem = (id) => {
    const newSelected = selected.filter((el) => el !== id)
    setSelected(newSelected)
  }

  const toggleItem = (id) => {
    if (selected.includes(id)) {
      unselectItem(id)
    } else {
      selectItem(id)
    }
  }

  const handleClickOrder = async () => {
    setSubmitting(true)

    const orderData = selected.map((ind) => {
      const part = enqResponse[ind]

      return {
        'Qty': part.Qty,
        'ResponseDetailID': part.ResponseDetailId,
        'UserID': cinfo.UserID,
        'ResponseHeaderID': part.ResponseHeaderId,
        'EnquiryID': part.EnquiryId,
        'Confirmed': 1
      }
    })

    await mutation.mutateAsync(orderData)
    setSubmitting(false)
  }

  const handleClickDl = async () => {
    console.info('Preparing CSV for download');

    // Generate CSV data
    const csvData = convertToCSV(enqResponse);

    // Create a Blob from the CSV String
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element, use it to download the blob, and remove it
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.setAttribute('download', 'enquiry_response.csv'); // Name the file
    tempLink.style.display = 'none';
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    successToast('CSV complete')
  };

  function convertToCSV(enqResponse) {
    // Define the CSV header
    warningToast('Building CSV')
    const csvHeader = "PartNumber,Qty,UnitPrice,ResponseDetailNotes\n";

    // Transform each item to a CSV row
    const csvRows = enqResponse.map(item => {
      const partNumber = item.PartNumber || "";
      const qty = item.Qty || "";
      const unitPrice = item.UnitPrice || "";
      const responseDetailNotes = item.ResponseDetailNotes || "";

      // Format the row and handle any potential commas in the values
      return `"${partNumber}","${qty}","${unitPrice}","${responseDetailNotes}"`;
    });

    // Combine header and rows, then return
    return csvHeader + csvRows.join("\n");
  }

  const totalPrice = selected.reduce((acc, cur) => {
    const price = parseFloat(enqResponse[cur].UnitPrice)
    return acc += (price * enqResponse[cur].Qty)
  }, 0)

  return (
    <div>
      {
        enqResponse[0] && (
          <div className="flex justify-center space-x-2">
            {enqResponse[0].Make && <Chip>{enqResponse[0].Make}</Chip>}
            {enqResponse[0].Model && <Chip>{enqResponse[0].Model}</Chip>}
            {enqResponse[0].Engine && <Chip>{enqResponse[0].Engine}</Chip>}
            {enqResponse[0].GBox && <Chip>{enqResponse[0].GBox}</Chip>}
            {enqResponse[0].Fuel && <Chip>{enqResponse[0].Fuel}</Chip>}
          </div>
        )
      }
      <Card style={{ height: 'auto' }}>
        <div className="mb-3 text-lg text-center">Please select the parts you would like to order.</div>
        <div className="flex justify-between items-end">
          <div>
            <div className="text-sm">Total selected</div>
            <span className={clsx(
              'text-4xl font-bold transition-colors',
              { '!text-gray-300': totalPrice === 0 }
            )}>
              {currencySymbol}{totalPrice.toFixed(2)}
            </span>
          </div>
          <Button
            variant="blue"
            big={true}
            onClick={handleClickDl}
            loading={isSubmitting}
          >
            D/L Quote
          </Button>
          <Button
            variant="green"
            big={true}
            disabled={totalPrice === 0}
            onClick={handleClickOrder}
            loading={isSubmitting}
          >
            Place Order
          </Button>
        </div>
      </Card>
      <div className="space-y-2" style={{ marginTop: 15 }}>
        {
          enqResponse.map((part, ind) => (
            <QuotedPartCard
              key={ind}
              part={part}
              isSelected={selected.includes(ind)}
              toggleSelected={() => toggleItem(ind)}
            />
          ))
        }
      </div>
    </div>
  )
}

const Chip = ({ children }) => {
  return (
    <div className="px-4 py-2 font-bold text-white bg-gray-500 rounded-full">{children}</div>
  )
}

const QuotedPartCard = ({ part, isSelected, toggleSelected }) => {
  const { currencySymbol } = useContext(GlobalContext)
  const price = parseFloat(part.UnitPrice).toFixed(2)
  const total = (parseFloat(part.UnitPrice) * part.Qty).toFixed(2)
  const dueDate = moment(part.DeliveryDate).isSame(new Date(), 'day') ? 'Today' : moment(part.DeliveryDate).format('DD/MM/YY')

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      successToast(`${text} copied to clipboard`);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className="flex">
      <div className="pr-1 flex-0">
        <Checkbox
          checked={isSelected}
          onClick={toggleSelected}
        />
      </div>
      <div className="flex-1">
        <Card
          onClick={toggleSelected}
          pad={false}
          className="cursor-pointer select-none"
        >
          <div className="p-5">
            <div className="flex justify-between items-center">
              <div onClick={() => copyToClipboard(part.PartNumber)} className="text-3xl font-bold">{part.PartNumber}</div>
              <div className={clsx(
                'text-xl font-bold transition-colors',
                { '!text-gray-300': isSelected }
              )}>
                Qty {part.Qty} @ {currencySymbol}{price}
              </div>
            </div>
            <div className="flex justify-between items-baseline">
              <div className="text-lg">Due: {dueDate}</div>
              <div className={clsx(
                'text-3xl font-bold text-white transition-colors',
                {
                  '!text-inherit': isSelected,
                  'select-none': !isSelected
                }
              )}>
                {currencySymbol}{total}
              </div>
            </div>
          </div>
          <div className="p-2 font-bold text-center text-white" style={{ backgroundColor: 'blue' }}>
            {part.ResponseDetailNotes || <div className="italic">No notes</div>}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default EnqQuoted