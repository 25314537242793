import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { Error, Row, Spacer, Footer, VSpacer } from 'components/ui/form/Form'
import TextInput from 'components/ui/form/TextInput'
import RegTextInput from 'components/ui/form/RegTextInput'
import { Button } from 'components/gsys-ui'
import RadioButton from 'components/ui/form/RadioButton'
import ImagePicker from 'components/ui/form/ImagePicker'
import PartsRadioButton from './PartsRadioButton'
import ConsumablesRadioButton from './ConsumablesRadioButton'
import { useFormikContext } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import Dropdown from 'components/ui/Dropdown'
import { checkReg } from 'util/api'

const EnquiryForm = ({ isSubmitting, handleSubmit, error }) => {
  const modal = useModal()
  const [buttonLocked, setButtonLocked] = useState(false)
  const { setFieldValue, values } = useFormikContext()
  const { quickLaunchParts, setVehicleString, setNotesExtra, apir, uinfo } = useContext(GlobalContext)
  const [deliveryTime, setDeliveryTime] = useState(null)

  const [regError, setRegError] = useState(null)

  const handleRegBlur = async (e) => {
    if (e.target.value.trim() === '') {
      setRegError(null)
      return
    }

    try {
      const vehicleData = await checkReg(uinfo.CustomerKeyCode, e.target.value, apir)
      console.log(vehicleData)

      if (vehicleData.data?.$?.['xsi:type'] === 'Error') {
        if (vehicleData.data.Description === 'No Vehicles Returned') {
          setRegError('ERR_NO_VEHICLE')
        } else if (
          vehicleData.data.Description === 'VRM wrong length - could not retrieve vehicle' ||
          vehicleData.data.Description === 'VRM cannot be longer than 7 characters'
        ) {
          setRegError('ERR_INVALID_REG')
        } else {
          setRegError('ERR_UNKNOWN')
        }

        return
      }

      if (vehicleData.data?.$?.['xsi:type'] === 'Vehicle') {
        setRegError(null)

        NiceModal.show('checkRegModal', {
          reg: e.target.value,
          vehicleData: vehicleData,
          submit: () => {
            setVehicleString(
              `\n\n${vehicleData.data.Make}, ${vehicleData.data.Model}, ${vehicleData.data.EngineSize}, ${vehicleData.data.Fuel}, ${vehicleData.data.YearOfManufacture}, ${vehicleData.data.Colour}, ${vehicleData.data.Vin}`
            )
          },
          cancel: () => {
            setFieldValue('Reg', '')
            setVehicleString('')
          }
        })
        return
      }

      setRegError('ERR_UNKNOWN')
    } catch (e) {
      if (e.response.data?.error === 'ERR_NOT_ALLOWED') {
        // do nothing, reg validation disabled
      } else if (e.response.data?.error === 'ERR_NO_CREDIT') {
        setRegError('ERR_NO_CREDIT')
      } else {
        setRegError('ERR_SERVER')
      }

      console.log(e)
    }
  }

  useEffect(() => {
    setVehicleString('')
    setNotesExtra('')
  }, [])

  useEffect(() => {
    if (deliveryTime === 'sameday') {
      setNotesExtra('[SAME DAY] ')
    } else if (deliveryTime === 'nextday') {
      setNotesExtra('[NEXT DAY] ')
    } else {
      setNotesExtra('')
    }
  }, [deliveryTime])

  const handlePartListModal = () => {
    NiceModal.show('partListModal', {
      submit: (partList) => {
        setFieldValue('Parts', partList)
      }
    })
  }

  const quickLaunchDropdown = quickLaunchParts.map((p, ind) => ({ id: ind, name: p }))

  return (
    <>
      {error && <Error>{error}</Error>}
      {regError &&
        (regError === 'ERR_NO_CREDIT' ? (
          <Error>You have run out of VRM lookup credits for this month.</Error>
        ) : regError === 'ERR_SERVER' ? (
          <Error>VRM lookup failed due to a server error.</Error>
        ) : regError === 'ERR_NO_VEHICLE' ? (
          <Error>No vehicle was found for this reg.</Error>
        ) : regError === 'ERR_INVALID_REG' ? (
          <Error>This appears to be an invalid reg.</Error>
        ) : regError === 'ERR_UNKNOWN' ? (
          <Error>VRM lookup failed due to an unkown error.</Error>
        ) : null)}
      <Row>
        <Button
          variant={deliveryTime === 'sameday' ? 'green' : 'white'}
          onClick={() => (deliveryTime === 'sameday' ? setDeliveryTime(null) : setDeliveryTime('sameday'))}
        >
          Same day
        </Button>
        <Button
          variant={deliveryTime === 'nextday' ? 'green' : 'white'}
          onClick={() => (deliveryTime === 'nextday' ? setDeliveryTime(null) : setDeliveryTime('nextday'))}
        >
          Next day
        </Button>
      </Row>
      <Spacer />
      <Row>
        <RegTextInput
          name="Reg"
          placeholder="Enter reg"
          size={2}
          disabled={values.Consumables === 'Consumables'}
          onBlur={handleRegBlur}
        />

        <div className="flex justify-between items-center w-full">
          <PartsRadioButton name="Consumables" label="Parts" value="Parts" />
          <ConsumablesRadioButton name="Consumables" label="Consumables" value="Consumables" />
        </div>
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-end w-full">
          <div className="flex-1">
            <TextInput name="Parts" label="Part details" />
          </div>
        </div>
      </Row>
      <Row>
        <div className="flex items-end mt-2 w-full">
          <div className="mr-2">
            <Dropdown
              values={quickLaunchDropdown}
              label="Use part shortcut"
              onClick={(v) => setFieldValue('Parts', v.name)}
            />
          </div>

          <div>
            <Button onClick={handlePartListModal}>Use part list</Button>
          </div>
        </div>
      </Row>
      <Spacer />
      <Row>
        <TextInput name="Notes" label="Notes" />
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-center w-full">
          <div className="flex flex-1 justify-between items-center w-full">
            <RadioButton name="Status" label="Quote" value="Waiting" />
            <RadioButton name="Status" label="Order" value="Order" />
          </div>
          <div className="flex-1 w-full">
            <ImagePicker name="Image" />
          </div>
        </div>
      </Row>
      <Spacer />
      <Footer>
        <Button variant="white" onClick={modal.remove}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={values.Consumables === 'Consumables' ? false : buttonLocked}
        >
          Submit
        </Button>
      </Footer>
    </>
  )
}

export default EnquiryForm
