import { createContext, useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { getPartList } from 'util/api'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [cinfo, setCinfo] = useState(null)
  const [currencySymbol, setCurrencySymbol] = useState(null)
  const [uinfo, setUinfo] = useState(null)
  const [brandingBlob, setBrandingBlob] = useState(null)
  const [quickLaunchParts, setQuickLaunchParts] = useLocalStorageState('quickLaunchParts', { defaultValue: [] })
  const [partLists, setPartLists] = useState([])
  const [apir, setApir] = useState(null)
  const [vehicleString, setVehicleString] = useState('')
  const [notesExtra, setNotesExtra] = useState('')
  const version = '1.1.2'

  useEffect(() => {
    if (!cinfo) return
    cinfo.CurrencyCode == 'GBP' ? setCurrencySymbol('£') : setCurrencySymbol('€')
  }, [cinfo])

  const addQuickLaunchPart = (part) => {
    if (quickLaunchParts.includes(part)) return false
    const newParts = [...quickLaunchParts, part]
    setQuickLaunchParts(newParts)
  }

  const deleteQuickLaunchPart = (part) => {
    let newParts = quickLaunchParts.filter((p) => p !== part)
    setQuickLaunchParts(newParts)
  }

  useEffect(() => {
    if (apir === null) return

    const loadPartList = async () => {
      try {
        const data = (await getPartList(apir)).data
        const formattedPartList = data.reduce((acc, item) => {
          acc[item.PartGroup] = item.PartList
          return acc
        }, {})

        setPartLists(formattedPartList)
      } catch (error) {
        console.error('Error loading part list:', error)
      }
    }

    loadPartList()
  }, [apir])

  const value = {
    cinfo,
    setCinfo,
    uinfo,
    setUinfo,
    currencySymbol,
    brandingBlob,
    setBrandingBlob,
    quickLaunchParts,
    addQuickLaunchPart,
    deleteQuickLaunchPart,
    version,
    apir,
    setApir,
    vehicleString,
    setVehicleString,
    partLists,
    setPartLists,
    notesExtra,
    setNotesExtra
  }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
